import appStrings from '@/app/utility/string.utility';
export default {
  name: 'ProjectArea',
  props: {
    parentProjectData: Object
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      projectId: null,
      projectAreaId: 0,
      areaType: null,
      superStructArea: null,
      superStructAreaUnit: {
        value: null,
        text: null
      },
      subStructArea: null,
      subStructAreaUnit: {
        value: null,
        text: null
      },
      landArea: null,
      landAreaUnit: {
        value: null,
        text: null
      },
      basementArea: null,
      basementAreaUnit: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjectArea();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    this.projectId = this.parentProjectData.project_id;
    this.getProjectArea();
  },
  methods: {
    getProjectArea() {
      const payload = {
        project_id: this.projectId
      };
      this.loader = true;
      this.$store
        .dispatch('projects/getProjectsProjectArea', payload)
        .then(response => {
          this.loader = false;
          const results = response.data.data;
          this.projectId = results.project_id;
          this.projectAreaId = results.project_area_id;
          this.superStructArea = results.super_structure_area;
          this.superStructAreaUnit = {
            value: results.super_structure_area_uom_vset,
            text: results.super_structure_area_meaning
          };
          this.subStructArea = results.sub_structure_area;
          this.subStructAreaUnit = {
            value: results.sub_structure_area_vset,
            text: results.sub_structure_area_meaning
          };
          this.landArea = results.land_area;
          this.landAreaUnit = {
            value: results.land_area_uom_vset,
            text: results.land_area_uom_meaning
          };
          this.basementArea = results.basement_area;
          this.basementAreaUnit = {
            value: results.basement_area_uom_vset,
            text: results.basement_area_uom_meaning
          };
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditProjectArea() {
      const payload = {
        basement_area: this.basementArea,
        basement_area_uom_vset: this.basementAreaUnit.value,
        land_area: this.landArea,
        land_area_uom_vset: this.landAreaUnit.value,
        project_area_id: this.projectAreaId,
        project_id: this.projectId,
        sub_structure_area: this.subStructArea,
        sub_structure_area_uom_vset: this.subStructAreaUnit.value,
        super_structure_area: this.superStructArea,
        super_structure_area_uom_vset: this.superStructAreaUnit.value
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectsProjectArea', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const results = response.data.data;
            this.projectAreaId = results.project_area_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'UOM') {
        this.superStructAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.subStructAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.landAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.basementAreaUnit = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode, areaType) {
      this.parent_value_set_id = this.intervalClass[0].detail_id;
      this.vsetCode = vsetCode;
      this.areaType = areaType;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'UOM') {
        this.superStructAreaUnit = {
          value: null,
          text: null
        };
        this.subStructAreaUnit = {
          value: null,
          text: null
        };
        this.landAreaUnit = {
          value: null,
          text: null
        };
        this.basementAreaUnit = {
          value: null,
          text: null
        };
      }
    }

  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
