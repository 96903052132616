import AddProjects from '../addProjects';
import ProjectArea from './projectsArea';
import OtherSpec from './otherSpec';
export default {
    name: 'ProjectsInfo',
    props: {
      parentProjectData: Object
    },
    components: {
      AddProjects,
      ProjectArea,
      OtherSpec
    },
    data() {
      return {
        actionName: null,
        loader: false,
        activeTab: 'ProjectDetails',
        parent_value_set_id: null
      };
    },
    mounted() {},
    methods: {
      selectedTab(tabValue) {
        this.activeTab = tabValue;
      },
      closeTabs() {
        this.$emit('closeProjectDetail');
        this.$emit('updateProjectProjectList');
      },
      buttonAccessPermissions(actionName) {
        this.actionName = actionName;
      }
    }
  };