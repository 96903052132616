import DatePicker from 'vue2-datepicker';
import Organization from '../../../admin/organization';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'AddProjects',
  props: {
    parentProjectData: Object
  },
  components: {
    DatePicker,
    Organization
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      masterPrj: {
        value: null,
        text: null
      },
      sector: {
        value: null,
        text: null
      },
      pocket: {
        value: null,
        text: null
      },
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      location: {
        value: null,
        text: null
      },
      projectType: {
        value: null,
        text: null
      },
      projectCode: null,
      projectName: null,
      projectId: 0,
      startDate: null,
      endDate: null,
      start_date_end_date: null,
      version: null,
      active: true
    };
  },
  mounted() {
    if (this.parentProjectData) {
      this.fillDataFromParent();
  } else { this.editMode = true }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditProjects();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.projectId });
        }
      }
    });
    
  },
  methods: {
    fillDataFromParent() {
      this.masterPrj = {
        value: this.parentProjectData.master_project_id,
        text: this.parentProjectData.master_project_name
      };
      this.sector = {
        value: this.parentProjectData.sector_id,
        text: this.parentProjectData.sector_name
      };
      this.pocket = {
        value: this.parentProjectData.pocket_id,
        text: this.parentProjectData.pocket_name
      };
      this.leName = {
        value: this.parentProjectData.le_id,
        text: this.parentProjectData.le_name
      };
      this.ouName = {
        value: this.parentProjectData.ou_id,
        text: this.parentProjectData.ou_name
      };
      this.location = {
        value: this.parentProjectData.location_id,
        text: this.parentProjectData.location_name
      };
      this.projectType = {
        value: this.parentProjectData.project_type_vset,
        text: this.parentProjectData.project_type
      };
      this.projectCode = this.parentProjectData.project_code;
      this.projectId = this.parentProjectData.project_id;
      this.projectName = this.parentProjectData.project_name;
      this.active = this.parentProjectData.active;
      this.startDate = this.parentProjectData.start_date;
      this.endDate = this.parentProjectData.end_date;
    },
    addEditProjects() {
      const payload = {
        active: this.active,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        location_id: this.location.value,
        sector_id: this.sector.value,
        pocket_id: this.pocket.value,
        project_name: this.projectName,
        project_id: this.projectId,
        master_project_id: this.masterPrj.value,
        project_code: this.projectCode,
        project_type_vset: this.projectType.value,
        start_date: this.startDate,
        end_date: this.endDate
      };
      this.loader = true;
      this.$store
        .dispatch('projects/addEditProjectsProject', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateProjectProjectList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.startDate
      );
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.pocket = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.pocket = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'PRJ_TYPE') {
        this.projectType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.location = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === 'PRJ_TYPE' || vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.parent_value_set_id = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.parent_value_set_id = this.masterPrj.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.parent_value_set_id = this.sector.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    openCloseOu(flag, orgType, component) {
      this.tab = component;
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.showModalComponent = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.leName.value = item.org_id;
        this.leName.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.ouName.value = item.org_id;
        this.ouName.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.showModalComponent = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: null,
          text: null
        };
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        this.sector = {
          value: null,
          text: null
        };
        this.pocket = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.PRJ_POCKET) {
        this.pocket = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'PRJ_TYPE') {
        this.projectType = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: null,
          text: null
        };
        this.ouName = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.location = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
